<template>
  <div id="estate">
    <div class="baner">
      <el-carousel
        :interval="4000"
        style="width: 100%"
        arrow="always"
        height="780px"
      >
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <el-image
            :class="className"
            fit="cover"
            style="width: 100%; height: 576px"
            :src="
              bannerdd.length !== 0
                ? 'https://gxzw.linjiaxiaoda.com/picture/prod-api' + item.image
                : item.image
            "
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="box">
      <div class="box-li">
        <div class="tac fs36 fw6" style="margin-top: 112px">
          智慧小区 聚达之家
        </div>
        <div class="flex JCcenter box-li-tiao">
          <div class="box-li-tiao-L"></div>
          <div class="box-li-tiao-R"></div>
        </div>
        <div class="box-li-bg"></div>
        <div class="box-li-text hui333 fs16">
          全流程多种应用场景互动，多种办事类型全覆盖，通过过社区活动、聚达之家app和微信平台持续优化居民体验，满足家庭全生命周期的生活需求，专享社区好配套、尊享品质好服务、臻享邻里好氛围，尽享幸福美好生活。
        </div>
      </div>
      <div class="box-li">
        <div class="tac fs36 fw6" style="margin-top: 112px">智慧小区物联网</div>
        <div class="flex JCcenter box-li-tiao">
          <div class="box-li-tiao-L"></div>
          <div class="box-li-tiao-R"></div>
        </div>
        <div class="box-li-text tac fs20 fw6 hui333" style="margin-top: 40px">
          公共设施设备
        </div>
        <div class="box-li-text tac hui333 fs16">
          建立由住宅小区综合物业管理中心与安防系统、信息服务系统、物业管理系统以及家居智能化组成的"三位一体"住宅小区服务和管理集成系统，为每个住户提供安全、舒适、温馨和便利的生活环境。
        </div>
        <div class="box-li-box flex FWw JCspaceBetween">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="flex JCspaceBetween ALcenter box-li-box-li"
          >
            <div class="fs20 fw6 hui333" style="margin-left: 44px">
              {{ item.name }}
            </div>
            <img :src="item.img" style="margin-right: 44px" />
          </div>
        </div>
      </div>
    </div>
    <Fuwu />
  </div>
</template>

<script>
import Fuwu from "@/components/Fuwu.vue";
import { getBanner } from "@/api/new";
export default {
  name: "Estate",
  components: {
    // Tabbar,
    // Bottombox,
    Fuwu,
  },
  data() {
    return {
      list: [
        {
          id: 1,
          name: "门禁设备",
          img: require("@/assets/estate1.png"),
        },
        {
          id: 2,
          name: "监控设备",
          img: require("@/assets/estate2.png"),
        },
        {
          id: 3,
          name: "消防设备",
          img: require("@/assets/estate3.png"),
        },
      ],
      bannerList: [{ id: 1, image: require("@/assets/xiaoqufuwu.png") }],
      bannerdd: [],
    };
  },
  mounted() {
    this.getbanner();
  },
  methods: {
    getbanner() {
      getBanner({ type: 4 }).then((res) => {
        console.log(res.data, "banner");
        this.bannerdd = res.data;
        if (res.data.length !== 0) {
          this.bannerList = res.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
#estate {
  .baner {
    width: 1920px;
    height: 576px;
    // background-image: url(~@/assets/xiaoqufuwu.png);
    // background-size: 100% 100%;
  }

  .box {
    width: 1200px;
    margin: 0 360px;
    .box-li {
      width: 1200px;

      .box-li-tiao {
        width: 1200px;
        height: 6px;
        margin-top: 21px;
        .box-li-tiao-L {
          width: 64px;
          height: 6px;
          background: #f8951d;
          margin-right: 8px;
        }
        .box-li-tiao-R {
          width: 12px;
          height: 6px;
          background: #f8951d;
        }
      }

      .box-li-bg {
        width: 1056px;
        height: 595px;
        margin: 50px 72px 0;
        background-image: url(~@/assets/xiaoqubg1.png);
        background-size: 100% 100%;
      }

      .box-li-text {
        width: 870px;
        margin: 0 165px;
        line-height: 36px;
      }

      .box-li-box {
        width: 1200px;
        margin-top: 60px;
        .box-li-box-li {
          width: 352px;
          height: 128px;
          border: 1px solid rgba(248, 149, 29, 0.6);
          border-radius: 8px;
          margin-block: 56px;
        }
      }
    }
  }
}
</style>